.contactus {
  position: relative;
  z-index: 1;
}

.contactus:hover::after {
  content: "";
  background-color: #1c3977;
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  animation: pulse 1.1s infinite;
  z-index: -1;
}



@keyframes pulse{
  0%{
    transform: scale(0.95);
    opacity: 0.75;
  }
  100%{
    transform: scale(1.35);
    opacity: 0;
  }
}


