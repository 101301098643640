/* Center the entire content vertically and horizontally */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* background-color: #f4f4f4; */
}

/* Style for the modal */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

/* Style for the form inputs and button */
form input,
form textarea,
form button {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

form button {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border: none;
}

form button:hover {
    background-color: #0056b3;
}

/* Style for the publication list */
ul {
    list-style-type: none;
    padding: 0;
}

li {
    background-color: #ffffff;
    margin: 10px 0;
    padding: 15px;
    width: 100%;
    /* max-width: 600px; */
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

li h2 {
    margin: 0;
    font-size: 1.5em;
}

li p {
    margin: 5px 0;
}

li button {
    margin-right: 10px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
}

li button:hover {
    background-color: #218838;
}

li button:last-of-type {
    background-color: #dc3545;
}

li button:last-of-type:hover {
    background-color: #c82333;
}

/* Center the buttons and form content */
button,
form,
ul {
    display: flex;
    flex-direction: column;
    align-items: center;
}
